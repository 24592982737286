import { Button, Flex, Grid, GridItem, HStack, Input, Select, Text, VStack } from "@chakra-ui/react"
import { Header } from "../../components/Header"
import Sidebar from "../../components/Sidebar"
import { SearchInput } from "../../components/SearchInput/search-input"
import Datatable from "../../components/Datatable/datatable"
import { useEffect, useState } from "react"
import { OrderInterface } from "./Interfaces/order-interface"
import { getAllOrdersByFilters, refoundOrder } from "../../services/api"
import { formatNumberToCurrency } from "../../util/currency"
import { Pagination } from "../../components/Pagination/pagination"
import moment from "moment"
import { Label } from "reactstrap"
import Alert from "../../components/Alert"
import { ToastContainer, toast } from "react-toastify"
import 'react-toastify/dist/ReactToastify.css';
import { InfoOrderModal } from "./info-order-modal"
import { getUser } from "../../services/auth"

enum StatusEnum {
    pending = 'PENDENTE',
    paid = 'PAGO',
    failed = 'ERRO',
    canceled = 'ESTORNADO',
    expired = 'EXPIRADO',
}

enum PaymentTypeEnum {
    pix = 'PIX',
    credit_card = 'CARTÃO DE CRÉDITO',
}

export const Orders = () => {

    const toLocalISOString = (date: Date): string => {
        const offset = date.getTimezoneOffset();
        const localDate = new Date(date.getTime() - offset * 60 * 1000);
        return localDate.toISOString().split('T')[0];
    }

    const [orders, setOrders] = useState<OrderInterface[]>([])
    const [lastPage, setLastPage] = useState<number>(1)
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [perPage, setPerPage] = useState<number>(50)
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [startDate, setStartDate] = useState<string>(toLocalISOString(new Date()))
    const [endDate, setEndDate] = useState<string>(toLocalISOString(new Date()))
    const [searchInput, setSearchInput] = useState<string>('')
    const [typeSearchSelected, setTypeSearchSelected] = useState<string>('event_or_customer')
    const [status, setStatus] = useState<string>('todos')
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [isOpenModalInfoOrder, setIsOpenModalInfoOrder] = useState<boolean>(false)
    const [order, setOrder] = useState<OrderInterface | null>(null)

    const userLogged = getUser()

    const handleModalRefound = (order: OrderInterface) => {
        setOrder(order)
        setIsOpen(true)
    }

    const habdleCloseModalRefound = () => {
        setIsOpen(false)
    }

    const handleRefound = async () => {
        try {
            const {data} = await refoundOrder(order!.id)

            toast.success("Estorno realizado com sucesso")
            habdleCloseModalRefound()
            handleSearch()

        } catch (error) {
            toast.error("Erro ao realizar extorno!")
        }
    }

    const columns = [
        { label: 'ID', value: 'id'},
        { label: 'CLIENTE', value: 'customer'},
        { label: 'VALOR TOTAL', value: 'amount'},
        { label: 'FORMA DE PAGAMENTO', value: 'payment_type'},
        { label: 'STATUS', value: 'status'},
        { label: 'DATA', value: 'created_at'},
        { label: 'Ações', value: 'actions'},
    ]

    const typeSearchArray = [
        {
            id: 1,
            label: "BUSCAR PELO ID",
            value: 'id',
        },
        {
            id: 2,
            label: "BUSCAR PELO NOME DO CLIENTE OU EVENTO",
            value: 'event_or_customer',
        },
    ]

    const statusArray = [
        {
            id: 1,
            label: "TODOS",
            value: 'todos',
        },{
            id: 2,
            label: "PAGO",
            value: 'paid',
        },
        {
            id: 3,
            label: "PENDENTE",
            value: 'pending',
        },
        {
            id: 4,
            label: "ERRO",
            value: 'failed',
        },
        {
            id: 5,
            label: "ESTORNADO",
            value: 'canceled',
        },
        {
            id: 5,
            label: "EXPIRADO",
            value: 'expired',
        },
    ]
    
    const rows = orders.map(order => {
        return {
            id: order.id,
            customer: order.customer.name,
            event: formatNumberToCurrency(order.amount),
            payment_type: (
                <p
                    style={{
                        fontWeight: 'bold',
                    }}
                >
                    {PaymentTypeEnum[order.payment_type]}
                </p>
            ),
            status: (
                <p
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    fontWeight: 'bold',
                    color: 'white',
                    background: order.status !== 'paid' ? 'orange' : 'green',
                    padding: '5px',
                    borderRadius: '10px',
                }}
                >
                    {StatusEnum[order.status]}
                </p>
            ),
            created_at: moment(order.created_at).format("DD/MM/YYYY HH:mm:ss"),
            action: [
                <Button
                    marginRight={5}
                    isDisabled={!(order.status === 'paid') || (userLogged.user_type === "ORGANIZER")}
                    color='white'
                    backgroundColor='red'
                    onClick={() => handleModalRefound(order)}
                >
                    Estornar
                </Button>,

                <Button
                    onClick={() => {
                        setIsOpenModalInfoOrder(true)
                        setOrder(order)
                    }}
                >
                    Ver Mais
                </Button>
            ]
        }
    })

    const handleSearch = async () => {

        await getOrders()
    }

    const getOrders = async () => {
        setIsLoading(true)
        try {
            const {data} = await getAllOrdersByFilters(currentPage, perPage, startDate, endDate, searchInput, typeSearchSelected, status)
            const {items, meta} = data
            setOrders(items)

            setLastPage(meta.totalPages)
            setCurrentPage(meta.currentPage)
            setPerPage(meta.itemsPerPage)
            
        } catch (error) {
            toast.error("Ocorreu um erro ao buscar os pedidos!")
        }
        setIsLoading(false)
    }

    useEffect(() => {
        handleSearch()
    }, [currentPage, perPage])

    useEffect(() => {
        if (searchInput === '' && !isLoading) {
            handleSearch();
        }
    }, [searchInput]);

    return(
        <>
            <Grid
                templateColumns="auto 1fr"
                templateRows="auto 1fr"
                minH="100vh"
                p={0}
                gap={0}
            >
                <GridItem
                    as="aside" 
                    colSpan={1} 
                    rowSpan={2} 
                    bg="gray.100" 
                    h="100vh"
                    position="sticky"
                    top="0"
                >
                    <Sidebar />
                </GridItem>

                <GridItem as="header" colStart={2} colSpan={1} bg="gray.200" w="100%">
                    <Header />
                </GridItem>

                <GridItem colStart={2} rowStart={2} w="100%" h="100%">
                    <Flex>
                        <div className="content">
                            <div style={{marginTop: '-5%'}} className="table-header">
                                <Text fontSize={"4xl"}>Pedidos</Text>
                            </div>

                            <VStack
                                style={{
                                display: 'flex',
                                justifyContent: 'space-around'
                                }}  
                            >
                                <HStack>
                                    <div
                                        style={{
                                            display: 'flex',
                                            marginRight: '20px'
                                        }}
                                    >
                                        <Input
                                            style={{
                                                width:'10rem'
                                            }}
                                            className="input"
                                            name="initialDate"
                                            placeholder="Data de inicio"
                                            size="md"
                                            type="date"
                                            onChange={(e) => setStartDate(e.target.value.split('T')[0])}
                                            value={startDate}
                                            errorBorderColor="pink.400"
                                        />

                                        <Label
                                            style={{
                                                margin: '10px 20px 10px 20px'
                                            }}
                                        >à</Label>

                                        <Input
                                            style={{
                                                width:'10rem'
                                            }}
                                            className="input"
                                            name="endDate"
                                            placeholder="Data final"
                                            size="md"
                                            type="date"
                                            onChange={(e) => setEndDate(e.target.value.split('T')[0])}
                                            value={endDate}
                                            errorBorderColor="pink.400"
                                        />
                                    </div>

                                    <div>
                                        <Select
                                            style={{
                                                width:'25rem'
                                            }}
                                            className="input"
                                            name="typeSearch"
                                            placeholder="Selecione o tipo de busca"
                                            onChange={(e) => setTypeSearchSelected(e.target.value)}
                                            value={typeSearchSelected}
                                            errorBorderColor="pink.400"
                                        >
                                            {typeSearchArray.map((type) => (
                                            <option key={type.id} value={type.value}>
                                                {type.label}
                                            </option>
                                            ))}
                                        </Select>
                                    </div>

                                    <div>
                                        <Select
                                            style={{
                                                width:'12rem'
                                            }}
                                            className="input"
                                            name="status"
                                            placeholder="Selecione o status"
                                            onChange={(e) => setStatus(e.target.value)}
                                            value={status}
                                            errorBorderColor="pink.400"
                                        >
                                            {statusArray.map((status) => (
                                            <option key={status.id} value={status.value}>
                                                {status.label}
                                            </option>
                                            ))}
                                        </Select>
                                    </div>
                                </HStack>

                                <HStack
                                    justifyContent='center'
                                    width={'100%'}
                                >
                                    <SearchInput
                                        width="56rem"
                                        searchInput={searchInput}
                                        setSearchInput={setSearchInput}
                                        currentPage={currentPage}
                                        setCurrentPage={setCurrentPage}
                                        handleSearch={handleSearch}
                                        placeholder={typeSearchSelected == 'id' ? "Busque pelo id do pedido" : "Busque pelo nome do cliente ou do evento"}
                                        inputType={typeSearchSelected == 'id' ? 'number' : 'text'}
                                    />
                                    
                                </HStack>
                                
                            </VStack>

                            <Datatable
                                rows={rows}
                                columns={columns}
                                isLoading={isLoading}
                            />

                            <Pagination
                                lastPage={lastPage}
                                currentPage={currentPage}
                                setLastPage={setLastPage}
                                setCurrentPage={setCurrentPage}
                                perPage={perPage}
                                setPerPage={setPerPage}
                            />

                        </div>
                    </Flex>
                </GridItem>
            </Grid>

            <InfoOrderModal
                isOpen={isOpenModalInfoOrder}
                setIsOpen={setIsOpenModalInfoOrder}
                order={order}
            />

            <Alert
                title={"ESTORNAR PEDIDO?"}
                confirmActionMessage={"Tem certeza que deseja estornar esse pedido ?"}
                confirmActionButtonText={"Estornar"}
                cancelActionButtonText={"Cancelar"}
                isOpen={isOpen}
                onClose={habdleCloseModalRefound}
                cancelRef={() => {}}
                onConfirm={handleRefound}
                confirmButtonText={""}
            />

            <ToastContainer />

        </>
    )
}