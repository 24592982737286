import { Button, Flex, Grid, GridItem, Input, Select, Text } from "@chakra-ui/react"
import { Header } from "../../components/Header"
import Sidebar from "../../components/Sidebar"
import Datatable from "../../components/Datatable/datatable"
import moment from "moment"
import { useEffect, useState } from "react"
import { EventReportInterface } from "./interfaces/event-report-interface"
import { ToastContainer, toast } from "react-toastify"
import { getAllCompanies, getEventsReport } from "../../services/api"
import { DetailsModal } from "./details-modal"
import { Pagination } from "../../components/Pagination/pagination"
import { Label } from "reactstrap"
import { CompanyInterface } from "../../interfaces/company-interface"
import { getFromLocalStorage } from "../../util/functions"
import { IUser } from "../../interfaces/user-interface"

export const EventsReport = () => {

    const toLocalISOString = (date: Date): string => {
        const offset = date.getTimezoneOffset();
        const localDate = new Date(date.getTime() - offset * 60 * 1000);
        return localDate.toISOString().split('T')[0];
    }

    const [events, setEvents] = useState<EventReportInterface[]>([])
    const [event, setEvent] = useState<EventReportInterface | null>(null)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [lastPage, setLastPage] = useState<number>(1)
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [perPage, setPerPage] = useState<number>(50)
    const [startDate, setStartDate] = useState<string>(toLocalISOString(new Date()))
    const [endDate, setEndDate] = useState<string>(toLocalISOString(new Date()))
    const [isOpenDetails, setIsOpenDetails] = useState<boolean>(false)
    const [companies, setCompanies] = useState<CompanyInterface[]>([]);
    const [companyId, setCompanyId] = useState<string>('');

    const [isfirstLoad, setIsfirstLoad] = useState(true);

    const user: IUser = JSON.parse(getFromLocalStorage('@ondeehoje-user'))

    const columns = [
        { label: 'ID', value: 'id'},
        { label: 'NOME', value: 'title'},
        { label: 'DATA INICIAL', value: 'initialDate'},
        { label: 'DATA FINAL', value: 'finalDate'},
        { label: 'STATUS', value: 'isActive'},
        { label: 'NOME DA EMPRESA', value: 'companyName'},
        { label: 'AÇÕES', value: 'actions'},
    ]

    const rows = events.map(event => {
        return {
            id: event.id,
            title: event.title,
            initialDate: moment(event.initialDate).format("DD/MM/YYYY HH:mm:ss"),
            finalDate: moment(event.finalDate).format("DD/MM/YYYY HH:mm:ss"),
            isActive: event.isActive ? 'ATIVO' : 'INATIVO',
            companyName: event.companyName,
            actions: [
                <Button
                    colorScheme="blue"
                    onClick={() => {
                        setEvent(event)
                        setIsOpenDetails(true)
                    }}
                >
                    Detalhes
                </Button>
            ]
        }
    })

    const fetchAllCompanies = async () => {
        try {
          const {data} =  await getAllCompanies()
          setCompanies(data.data.itens)
          
        } catch (error) {
          toast.error('Ocorreu um erro ao buscar as empresas!')
        }
    }

    const fetchReport = async () => {
        try {
            const {data} = await getEventsReport({startDate: startDate, endDate: endDate, limit: perPage, page: currentPage, companyId, isfirstLoad})

            setEvents(data.items)
            setLastPage(data.meta.totalPages)
            setCurrentPage(data.meta.currentPage)
            setPerPage(data.meta.itemsPerPage)
            setIsfirstLoad(false)
            
        } catch (error) {
            toast.error('Ocorreu um erro ao listar eventos!')
        }
    }

    useEffect(() => {
        fetchAllCompanies()
    },[])

    useEffect(() => {
        fetchReport()
    },[currentPage, perPage])

    return(
        <>
            <Grid
                templateColumns="auto 1fr"
                templateRows="auto 1fr"
                minH="100vh"
                p={0}
                gap={0}
            >
                <GridItem
                    as="aside" 
                    colSpan={1} 
                    rowSpan={2} 
                    bg="gray.100" 
                    h="100vh"
                    position="sticky"
                    top="0"
                >
                    <Sidebar />
                </GridItem>

                <GridItem as="header" colStart={2} colSpan={1} bg="gray.200" w="100%">
                    <Header />
                </GridItem>

                <GridItem colStart={2} rowStart={2} w="100%" h="100%">
                    <Flex>
                        <div className="content">
                            <div style={{marginTop: '-5%'}} className="table-header">
                                <Text fontSize={"4xl"}>Relatório de Eventos</Text>
                            </div>

                            <div
                                style={{
                                display: 'flex',
                                justifyContent: 'center'
                                }}  
                            >
                                <Input
                                    style={{
                                        width:'10rem'
                                    }}
                                    className="input"
                                    name="initialDate"
                                    placeholder="Data de inicio"
                                    size="md"
                                    type="date"
                                    onChange={(e) => setStartDate(e.target.value.split('T')[0])}
                                    value={startDate}
                                    errorBorderColor="pink.400"
                                />

                                <Label
                                    style={{
                                        margin: '10px 20px 10px 20px'
                                    }}
                                >à</Label>

                                <Input
                                    style={{
                                        width:'10rem'
                                    }}
                                    className="input"
                                    name="endDate"
                                    placeholder="Data final"
                                    size="md"
                                    type="date"
                                    onChange={(e) => setEndDate(e.target.value.split('T')[0])}
                                    value={endDate}
                                    errorBorderColor="pink.400"
                                />

                                <Select
                                    width={200}
                                    marginLeft={50}
                                    className="input"
                                    name="company_id"
                                    placeholder={user.user_type === "ORGANIZER" ? undefined : "Selecione a empresa"}
                                    onChange={(e) => setCompanyId(e.target.value)}
                                    value={companyId}
                                >
                                    {companies.map((company) => (
                                        <option key={company.id} value={company.id}>
                                        {company.company_name ?? company.name}
                                        </option>
                                    ))}
                                </Select>

                                <Button
                                    marginLeft={50}
                                    colorScheme="blue"
                                    onClick={() => {
                                        if (currentPage === 1) {
                                            fetchReport()
                                        } else {
                                            setCurrentPage(1)
                                        }
                                    }}
                                >
                                    Buscar
                                </Button>
                            </div>

                            <Datatable
                                rows={rows}
                                columns={columns}
                                isLoading={isLoading}
                            />

                            <Pagination
                                lastPage={lastPage}
                                currentPage={currentPage}
                                setLastPage={setLastPage}
                                setCurrentPage={setCurrentPage}
                                perPage={perPage}
                                setPerPage={setPerPage}
                            />

                            { user.user_type == 'ADMIN' && (
                                <Select
                                    width={200}
                                    marginLeft={50}
                                    className="input"
                                    name="company_id"
                                    placeholder="Selecione a empresa"
                                    onChange={(e) => setCompanyId(e.target.value)}
                                    value={companyId}
                                >
                                    {companies.map((company) => (
                                        <option key={company.id} value={company.id}>
                                        {company.company_name ?? company.name}
                                        </option>
                                    ))}
                                </Select>
                            )}
                        </div>
                    </Flex>
                </GridItem>
            </Grid>

            <DetailsModal
                event={event}
                isOpen={isOpenDetails}
                setIsOpen={setIsOpenDetails}
            />
        </>
    )
}