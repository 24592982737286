import React, { useRef, useState } from "react";

import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  IconButton,
  useDisclosure,
  Text,
} from "@chakra-ui/react";

import { CheckIcon, DeleteIcon, EditIcon } from "@chakra-ui/icons";

import "./style.css";
import Alert from "../../../../components/Alert";
import Drawer from "../../../../components/Drawer";
import {
  updateActiveTicket,
  updateTickets,
} from "../../../../services/api";
import { useToast } from "@chakra-ui/react";
import TicketForm from "../TicketForm";
import { formatNumberToCurrency } from "../../../../util/currency";
import { ITicket } from "../../../../interfaces/ticket-interface";
import moment from "moment";

interface TicketTableProps {
  tickets: ITicket[];
  list: (eventId: number) => void;
  eventId: number;
}

export default function TicketTable({
  tickets,
  list,
  eventId,
}: TicketTableProps): JSX.Element {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isOpenModal, onClose: onCloseModal, onOpen: onOpenModal } = useDisclosure();
  const cancelRef = useRef();
  const toast = useToast();

  const [ticket, setTicket] = useState<ITicket>();
  const [isActive, setIsActive] = useState(false);

  function middlewareEdit(ticket: ITicket) {
    setTicket(ticket);
    onOpenModal();
  }

  function middlewareActive(ticket: ITicket) {
    setTicket(ticket);
    setIsActive(!ticket.is_active)
    onOpen();
  }

  function handleConfirmActive() {
    if (!ticket?.id) return;

    updateActiveTicket(ticket.id)
      .then((res) => {
        list(eventId);
        onClose();
        if(res.data.error){
          toast({
            title: res.data.message,
            status: "error",
            duration: 4000,
            isClosable: true,
          })
        }else{
          toast({
            title: "Ticket atualizado com sucesso.",
            status: "success",
            duration: 4000,
            isClosable: true,
          })
        }
        
      })
      .catch(() => {
        toast({
          title: "Erro ao atualizar ingresso.",
          description: "Verifique se o ingresso foi atualizado.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
  }

  function handleUpdate(data: ITicket) {
    if (!ticket?.id) return;

    updateTickets(ticket.id, {...data, eventId})
      .finally(() => {
        list(eventId);
        onCloseModal();
        toast({
          title: "Ticket atualizado com sucesso.",
          status: "success",
          duration: 4000,
          isClosable: true,
        })
      })
      .catch(() => {
        toast({
          title: "Erro ao atualizar ingresso.",
          description: "Verifique se o ingresso ainda existe.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
  }

  function renderActionButtons(ticket: ITicket) {
    return (
      <div className="action-buttons">
        <IconButton
          colorScheme="blue"
          aria-label="Editar ingresso"
          icon={<EditIcon />}
          onClick={() => middlewareEdit(ticket)}
        />
        <IconButton
          isDisabled={new Date(ticket.finalDate) <= new Date()}
          title={ticket.is_active ? "Inativar ingresso" : "Ativar ingresso"}
          colorScheme={ticket.is_active ? "red" : "green"}
          aria-label={ticket.is_active ? "Inativar ingresso" : "Ativar ingresso"}
          icon={<CheckIcon />}
          onClick={() => middlewareActive(ticket)}
        />
      </div>
    );
  }

  return (
    <>
      {tickets.length > 0 ? (
        <TableContainer className="table-container">
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th width={"2xl"}>Título</Th>
                <Th>Quantidade</Th>
                <Th>Data de Expiração</Th>
                <Th>Status</Th>
                <Th>Preço</Th>
                <Th>Ações</Th>
              </Tr>
            </Thead>
            <Tbody className="tr">
              {tickets.map((ticket) => (
                <Tr key={ticket.id}>
                  <Td>{ticket.title}</Td>
                  <Td>{ticket.quantity == 0 ? 'ESGOTADO' : ticket.quantity}</Td>
                  <Td>{moment(ticket.finalDate).format('DD/MM/YYYY HH:mm:ss')}</Td>
                  <Td color={ticket.is_active ? 'green' : 'red'}><strong>{ticket.is_active ? 'ATIVO' : 'INATIVO'}</strong></Td>
                  <Td>{formatNumberToCurrency(ticket.original_price ?? ticket.price)}</Td>
                  <Td>{renderActionButtons(ticket)}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      ) : (
        <Text align={"center"} marginTop={20}>
          Nenhum ingresso cadastrado
        </Text>
      )}

      <Alert
        title={"Deletar ingresso"}
        confirmActionMessage={`Tem certeza que deseja ${isActive ? 'ATIVAR' : 'DESATIVAR'} este ingresso ?`}
        confirmActionButtonText={isActive ? 'ATIVAR' : 'DESATIVAR'}
        cancelActionButtonText={"Cancelar"}
        isOpen={isOpen}
        onClose={onClose}
        cancelRef={cancelRef}
        onConfirm={handleConfirmActive}
        confirmButtonText={""}
      />

      <TicketForm
        onSubmit={handleUpdate}
        initialValues={ticket}
        onClose={onCloseModal}
        isOpen={isOpenModal}
      />
    </>
  );
}
